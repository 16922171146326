<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-header border-0">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Files</h3>
      </div>
    </div>

    <div id="kt_account_profile_details" class="collapse show">
        <div class="card-body border-top p-9">
          <div class="row mb-6 border-bottom border-gray-400" style="padding-bottom:10px;">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Application
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :on-change="(file) => uploadFile(file, 'application')"
                    :auto-upload="false"
                  >
                    <el-button size="small" type="primary">Click to upload</el-button>
                  </el-upload>
                  <el-table class="down" v-if="studentFiles.application.length > 0" :data="studentFiles.application" border stripe style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="file_name" label="File name"></el-table-column>
                    <el-table-column prop="create_date" label="Upload time">
                      <template v-slot="scope">
                          {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" label="operation">
                        <template v-slot="scope">
                            <el-button size="small" type="text">
                                <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                            </el-button>
                            <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6 border-bottom border-gray-400" style="padding-bottom:10px;">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Passport
            </label>

            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :on-change="(file) => uploadFile(file, 'passport')"
                    :auto-upload="false"
                  >
                    <el-button size="small" type="primary">Click to upload</el-button>
                  </el-upload>
                  <el-table class="down" v-if="studentFiles.passport.length > 0" :data="studentFiles.passport" border stripe style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="file_name" label="File name"></el-table-column>
                    <el-table-column prop="create_date" label="Upload time">
                      <template v-slot="scope">
                          {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" label="operation">
                        <template v-slot="scope">
                            <el-button size="small" type="text">
                                <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                            </el-button>
                            <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6 border-bottom border-gray-400" style="padding-bottom:10px;">
            <label class="col-lg-4 col-form-label fw-bold fs-6"
              >Visa</label
            >
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :on-change="(file) => uploadFile(file, 'visa')"
                    :auto-upload="false"
                  >
                    <el-button size="small" type="primary">Click to upload</el-button>
                  </el-upload>
                  <el-table class="down" v-if="studentFiles.visa.length > 0" :data="studentFiles.visa" border stripe style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="file_name" label="File name"></el-table-column>
                    <el-table-column prop="create_date" label="Upload time">
                      <template v-slot="scope">
                          {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" label="operation">
                        <template v-slot="scope">
                            <el-button size="small" type="text">
                                <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                            </el-button>
                            <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6 border-bottom border-gray-400" style="padding-bottom:10px;">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Bank Statement
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :on-change="(file) => uploadFile(file, 'bankStatement')"
                    :auto-upload="false"
                  >
                    <el-button size="small" type="primary">Click to upload</el-button>
                  </el-upload>
                  <el-table class="down" v-if="studentFiles.bankStatement.length > 0" :data="studentFiles.bankStatement" border stripe style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="file_name" label="File name"></el-table-column>
                    <el-table-column prop="create_date" label="Upload time">
                      <template v-slot="scope">
                          {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" label="operation">
                        <template v-slot="scope">
                            <el-button size="small" type="text">
                                <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                            </el-button>
                            <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6 border-bottom border-gray-400" style="padding-bottom:10px;">
            <label class="col-lg-4 col-form-label fw-bold fs-6"
              >Transfer Form
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :on-change="(file) => uploadFile(file, 'transferForm')"
                    :auto-upload="false"
                  >
                    <el-button size="small" type="primary">Click to upload</el-button>
                  </el-upload>
                  <el-table class="down" v-if="studentFiles.transferForm.length > 0" :data="studentFiles.transferForm" border stripe style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="file_name" label="File name"></el-table-column>
                    <el-table-column prop="create_date" label="Upload time">
                      <template v-slot="scope">
                          {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" label="operation">
                        <template v-slot="scope">
                            <el-button size="small" type="text">
                                <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                            </el-button>
                            <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6 border-bottom border-gray-400" style="padding-bottom:10px;">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              i-20
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :on-change="(file) => uploadFile(file, 'i20')"
                    :auto-upload="false"
                  >
                    <el-button size="small" type="primary">Click to upload</el-button>
                  </el-upload>
                  <el-table class="down" v-if="studentFiles.i20.length > 0" :data="studentFiles.i20" border stripe style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="file_name" label="File name"></el-table-column>
                    <el-table-column prop="create_date" label="Upload time">
                      <template v-slot="scope">
                          {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" label="operation">
                        <template v-slot="scope">
                            <el-button size="small" type="text">
                                <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                            </el-button>
                            <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6 border-bottom border-gray-400" style="padding-bottom:10px;">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Acceptance Letter
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :on-change="(file) => uploadFile(file, 'acceptanceLetter')"
                    :auto-upload="false"
                  >
                    <el-button size="small" type="primary">Click to upload</el-button>
                  </el-upload>
                  <el-table class="down" v-if="studentFiles.acceptanceLetter.length > 0" :data="studentFiles.acceptanceLetter" border stripe style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="file_name" label="File name"></el-table-column>
                    <el-table-column prop="create_date" label="Upload time">
                      <template v-slot="scope">
                          {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" label="operation">
                        <template v-slot="scope">
                            <el-button size="small" type="text">
                                <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                            </el-button>
                            <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              Others
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :on-change="(file) => uploadFile(file, 'others')"
                    :auto-upload="false"
                  >
                    <el-button size="small" type="primary">Click to upload</el-button>
                  </el-upload>
                  <el-table class="down" v-if="studentFiles.others.length > 0" :data="studentFiles.others" border stripe style="width: 100%;margin-top: 20px;">
                    <el-table-column prop="file_name" label="File name"></el-table-column>
                    <el-table-column prop="create_date" label="Upload time">
                      <template v-slot="scope">
                          {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                      </template>
                    </el-table-column>
                    <el-table-column width="150px" label="operation">
                        <template v-slot="scope">
                            <el-button size="small" type="text">
                                <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                            </el-button>
                            <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, reactive, onBeforeMount } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "student-settings",
  components: {
  },
  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const studentFiles = reactive({
      application : [] as any,
      passport : [] as any,
      visa : [] as any,
      bankStatement : [] as any,
      transferForm : [] as any,
      i20 : [] as any,
      acceptanceLetter : [] as any,
      others : [] as any
    });

    const route = useRoute();
    const id : number = +route.params.id;     
    const uploadUrl = process.env.VUE_APP_API_BASE_URL+'/file/upload/student/'+id;

    const uploadFile = async(file, type) =>{
      if(file && type){
      await new Promise<void>((resolve, reject) => { 
        var fd = new window.FormData();
        fd.append('file', file.raw, file.name);
        var url = uploadUrl+"/"+type;
        ApiService.setHeader();
        ApiService.setFormDataHeader();
        ApiService.post(url, fd as any)
          .then(() => {
            uploadSuccess();
            resolve();
          })
          .catch((error) =>{
            console.log('beforeUpload error => '+error);
            reject(new Error ('beforeUpload error => '+error));
          });
      });
      }
    }

    const getStudentFiles = async () => {
        await new Promise<void>((resolve, reject) => { 
            ApiService.setHeader();           
            ApiService.get("file/student/"+id)
                .then(({ data }) => {
                    studentFiles.application = data.data.filter(x=> x.file_type == 'application');
                    studentFiles.passport = data.data.filter(x=> x.file_type == 'passport');
                    studentFiles.visa = data.data.filter(x=> x.file_type == 'visa');
                    studentFiles.bankStatement = data.data.filter(x=> x.file_type == 'bankStatement');
                    studentFiles.transferForm = data.data.filter(x=> x.file_type == 'transferForm');
                    studentFiles.i20 = data.data.filter(x=> x.file_type == 'i20');
                    studentFiles.acceptanceLetter = data.data.filter(x=> x.file_type == 'acceptanceLetter');
                    studentFiles.others = data.data.filter(x=> x.file_type == 'others');
                    resolve();
                })
                .catch((error) => {
                    console.log('getStudentFiles error => '+error);
                    reject(new Error ('getStudentFiles error => '+error));
                });
        });
    }

    const downloadFile = (id,name) => {
        ApiService.setHeader();
        ApiService.get("file/url/"+id)
        .then(({ data }) => {
          var a = document.createElement('a');
          var event = new MouseEvent('click');
          a.download = name;
          a.href = data.url;
          a.dispatchEvent(event);
        })
        .catch((error)=>{
            console.log('getStudentFiles error => '+error);
        })
    }

    const deleteSavedFile = (row) =>{
      ElMessageBox.confirm('Are you sure to delete this record?')
        .then(() => {
          ApiService.setHeader();
          ApiService.delete("file/"+row.id)
          .then(({ data }) => {
            if(data.statusCode != 200){
              Swal.fire({
                    text: "Deleting file error",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Please try again",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
            }
            else{
              Swal.fire({
                    text: "Deleted successfully",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() => {
                      getStudentFiles();
                  });
            }
        })
        .catch((error)=>{
            console.log('getStudentFiles error => '+error);
        });
      });
    }

    const uploadSuccess = () => {
        Swal.fire({
                  text: "Uploaded successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    getStudentFiles();
                });
    }
    
    const handleUploadSuccess = (res, file, fileList) => {
        Swal.fire({
                  text: "Uploaded successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    getStudentFiles();
                });
    }

    onBeforeMount(() => {
        getStudentFiles();
    });

    onMounted(() => {
        setCurrentPageBreadcrumbs("Files", ["Student"]);
    });

    return {
      submitButton,
      studentFiles,
      downloadFile,
      deleteSavedFile,
      handleUploadSuccess,
      uploadUrl,
      moment,
      uploadFile
    };
  },
});
</script>
<style scoped>
* >>> .el-upload-list{
  display:none;
}
</style>