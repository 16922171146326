
import { defineComponent, onMounted, ref, computed, reactive, onBeforeMount } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "student-settings",
  components: {
  },
  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const studentFiles = reactive({
      application : [] as any,
      passport : [] as any,
      visa : [] as any,
      bankStatement : [] as any,
      transferForm : [] as any,
      i20 : [] as any,
      acceptanceLetter : [] as any,
      others : [] as any
    });

    const route = useRoute();
    const id : number = +route.params.id;     
    const uploadUrl = process.env.VUE_APP_API_BASE_URL+'/file/upload/student/'+id;

    const uploadFile = async(file, type) =>{
      if(file && type){
      await new Promise<void>((resolve, reject) => { 
        var fd = new window.FormData();
        fd.append('file', file.raw, file.name);
        var url = uploadUrl+"/"+type;
        ApiService.setHeader();
        ApiService.setFormDataHeader();
        ApiService.post(url, fd as any)
          .then(() => {
            uploadSuccess();
            resolve();
          })
          .catch((error) =>{
            console.log('beforeUpload error => '+error);
            reject(new Error ('beforeUpload error => '+error));
          });
      });
      }
    }

    const getStudentFiles = async () => {
        await new Promise<void>((resolve, reject) => { 
            ApiService.setHeader();           
            ApiService.get("file/student/"+id)
                .then(({ data }) => {
                    studentFiles.application = data.data.filter(x=> x.file_type == 'application');
                    studentFiles.passport = data.data.filter(x=> x.file_type == 'passport');
                    studentFiles.visa = data.data.filter(x=> x.file_type == 'visa');
                    studentFiles.bankStatement = data.data.filter(x=> x.file_type == 'bankStatement');
                    studentFiles.transferForm = data.data.filter(x=> x.file_type == 'transferForm');
                    studentFiles.i20 = data.data.filter(x=> x.file_type == 'i20');
                    studentFiles.acceptanceLetter = data.data.filter(x=> x.file_type == 'acceptanceLetter');
                    studentFiles.others = data.data.filter(x=> x.file_type == 'others');
                    resolve();
                })
                .catch((error) => {
                    console.log('getStudentFiles error => '+error);
                    reject(new Error ('getStudentFiles error => '+error));
                });
        });
    }

    const downloadFile = (id,name) => {
        ApiService.setHeader();
        ApiService.get("file/url/"+id)
        .then(({ data }) => {
          var a = document.createElement('a');
          var event = new MouseEvent('click');
          a.download = name;
          a.href = data.url;
          a.dispatchEvent(event);
        })
        .catch((error)=>{
            console.log('getStudentFiles error => '+error);
        })
    }

    const deleteSavedFile = (row) =>{
      ElMessageBox.confirm('Are you sure to delete this record?')
        .then(() => {
          ApiService.setHeader();
          ApiService.delete("file/"+row.id)
          .then(({ data }) => {
            if(data.statusCode != 200){
              Swal.fire({
                    text: "Deleting file error",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Please try again",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
            }
            else{
              Swal.fire({
                    text: "Deleted successfully",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() => {
                      getStudentFiles();
                  });
            }
        })
        .catch((error)=>{
            console.log('getStudentFiles error => '+error);
        });
      });
    }

    const uploadSuccess = () => {
        Swal.fire({
                  text: "Uploaded successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    getStudentFiles();
                });
    }
    
    const handleUploadSuccess = (res, file, fileList) => {
        Swal.fire({
                  text: "Uploaded successfully",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                    getStudentFiles();
                });
    }

    onBeforeMount(() => {
        getStudentFiles();
    });

    onMounted(() => {
        setCurrentPageBreadcrumbs("Files", ["Student"]);
    });

    return {
      submitButton,
      studentFiles,
      downloadFile,
      deleteSavedFile,
      handleUploadSuccess,
      uploadUrl,
      moment,
      uploadFile
    };
  },
});
